/* eslint-disable @typescript-eslint/no-explicit-any */
import { Formik } from 'formik'
import React, { useEffect, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'

import participantFormContent from './contents/PartipantFormContent'
import Form from './elements/Form'
import FormButton from './elements/FormButton'
import FormGrid from './elements/FormGrid'
import participantSchema from './schemas/ParticipantSchema'
import { TshirtSize } from '../../API'
import Dialog from '../elements/Dialog'

interface CategoryProps {
  id: string
  name: string
  disabled?: boolean
  isTandem: boolean
  tandemAgeRange: string
  eventEndDateTime: string
  isTriathlon: boolean
  teamType?: string
}
interface CurrentUserProps {
  email: string
  firstName: string
  lastName: string
  phoneNumber: string
}
interface ParticipantProps {
  id?: string
  bibName?: string
  firstName: string
  lastName: string
  shirtSize: TshirtSize
}
interface DialogProps {
  open: boolean
  onClose?: () => void
}
interface Props {
  categories: CategoryProps[]
  bonusCategory?: string
  selectedRelayEvent?: string[]
  currentUser: CurrentUserProps
  participant?: ParticipantProps | Record<string, unknown>
  updateData?: (values: any) => any
  onCancel: React.MouseEventHandler<HTMLButtonElement>
  editing?: boolean
  setLoading?: (value: boolean) => void
  displayError?: (values: any) => void
  dialogProps: DialogProps
  // Additional ID
  hasAdditionalId: boolean
  additionalIdFieldLabel: string
  firstParticipants?: any
  isOnsiteRegistration?: boolean
  subCategories?: any
  hasJersey?: boolean
}

const ParticipantForm: React.FC<Props> = ({
  categories,
  bonusCategory,
  selectedRelayEvent,
  currentUser,
  participant,
  updateData,
  onCancel,
  editing,
  setLoading,
  displayError,
  dialogProps,
  hasAdditionalId,
  additionalIdFieldLabel,
  firstParticipants,
  isOnsiteRegistration = false,
  subCategories,
  hasJersey = false,
}) => {
  const [checkCurrentUser, setCheckCurrentUser] = useState<boolean>(false)
  const [tandemInfo, setTandemInfo] = useState<any>({
    isTandem: false,
    tandemAgeRange: '',
    eventEndDateTime: '',
  })
  const [isTriathlon, setIsTriathlon] = useState(false)
  const [teamType, setTeamType] = useState('')

  useEffect(() => {
    if (categories.length === 1) {
      setIsTriathlon(categories[0].isTriathlon)
      setTeamType(categories[0].teamType || '')
    }
  }, [categories])

  return (
    <Formik
      initialValues={{
        isTriathlon,
        isOnsiteRegistration,
        relayEvent: [],
        ...participant,
        ...(categories.length === 1 && {
          categoryId: categories[0].id,
        }),
        firstParticipants,
        subCategories,
        teamType,
        hasJersey,
      }}
      validateOnMount={editing ? true : false}
      enableReinitialize
      validateOnBlur={true}
      validationSchema={participantSchema}
      onSubmit={async (values, actions) => {
        setLoading(true)
        if (!('id' in values)) values['id'] = uuidv4()
        Object.keys(values).forEach((key) => {
          if (typeof values[key] === 'string') values[key] = values[key]?.trim()
        })
        const [, error] = await updateData(values)
        if (error) {
          displayError({ title: error.title, message: error.message })
        } else {
          actions.resetForm()
        }
        setCheckCurrentUser(false)
        setLoading(false)
        actions.setSubmitting(false)
      }}>
      {(form) => {
        const updateField = (key, value) => {
          form.setFieldValue(key, value)
          form.setTouched({
            ...form.touched,
            [key]: participant[key] !== value,
          })
        }

        const handleCheckCurrentUser = (e) => {
          if (e.target.name === 'checkCurrentUser')
            setCheckCurrentUser(e.target.checked)

          form.setFieldValue(
            'firstName',
            e.target.checked ? currentUser.firstName : '',
          )
          form.setFieldValue(
            'lastName',
            e.target.checked ? currentUser.lastName : '',
          )
          form.setFieldValue(
            'phoneNumber',
            e.target.checked ? currentUser.phoneNumber : '',
          )
          form.setFieldValue(
            'confirmPhoneNumber',
            e.target.checked ? currentUser.phoneNumber : '',
          )
        }

        const handleCategoryChange = (e) => {
          const selectedCat =
            categories.filter((cat) => cat.id === e.target.value)?.[0] || null
          if (selectedCat) {
            if ('isTandem' in selectedCat) {
              setTandemInfo({
                isTandem: selectedCat.isTandem || false,
              })
              form.setFieldValue('isTandem', selectedCat.isTandem || false)
              form.setFieldValue(
                'tandemAgeRange',
                `${selectedCat.tandemAgeRange}`,
              )
            }
            form.setFieldValue('eventEndDateTime', selectedCat.eventEndDateTime)
          }
        }

        const handleRelayEventChange = (e) => {
          if (e.target.name.startsWith('relayEvent')) {
            const nameIdx = e.target.name.indexOf('-')
            if (nameIdx !== -1) {
              const relayEventValue = e.target.name.substr(nameIdx + 1)

              let newRelayEvent = (
                'relayEvent' in form.values ? form.values.relayEvent : []
              ) as string[]
              if (e.target.checked) {
                if (!newRelayEvent.includes(relayEventValue))
                  newRelayEvent = [...newRelayEvent, relayEventValue]
              } else {
                newRelayEvent = newRelayEvent.filter(
                  (name) => name !== relayEventValue,
                )
              }

              updateField('relayEvent', newRelayEvent)
            }
          }
        }

        return (
          <Form id="participant-form">
            <Dialog
              fullWidth
              maxWidth="lg"
              overflow="scroll-y"
              content={
                <FormGrid
                  content={participantFormContent({
                    values: form.values,
                    updateField,
                    formDisabled: form.isSubmitting,
                    categories:
                      bonusCategory.trim().length === 0
                        ? categories
                        : categories.filter((c) => c.id === bonusCategory),
                    checkCurrentUser,
                    handleCheckCurrentUser,
                    handleCategoryChange,
                    tandemInfo,
                    hasAdditionalId,
                    additionalIdFieldLabel,
                    handleRelayEventChange,
                    selectedRelayEvent,
                    currentRelayEvent: form.initialValues.relayEvent || [],
                    firstParticipants: firstParticipants || {},
                    isOnsiteRegistration,
                    subCategories: subCategories || {},
                    hasJersey,
                  })}
                />
              }
              buttons={
                <>
                  <FormButton
                    secondary
                    text
                    onClick={onCancel}
                    disabled={form.isSubmitting}
                    form="participant-form">
                    Cancel
                  </FormButton>
                  <FormButton
                    submit
                    primary
                    text
                    disabled={
                      (form.dirty && !form.isValid) ||
                      !form.dirty ||
                      form.isSubmitting
                    }
                    form="participant-form">
                    {editing ? 'Update' : 'Create'}
                  </FormButton>
                </>
              }
              {...dialogProps}
            />
          </Form>
        )
      }}
    </Formik>
  )
}
export default ParticipantForm
