/* eslint-disable react/react-in-jsx-scope */
import { TSHIRT_SIZES, GENDERS, COUNTRIES } from '../../../constants/models'
import FormDatePicker from '../elements/FormDatePicker'
import FormSelectInput from '../elements/FormSelectInput'
import FormTextInput from '../elements/FormTextInput'

const IndividualUserFormContent = ({
  isVirtual,
  formDisabled,
  showRepeater,
  hasBibName,
  hasJersey,
}) => [
  {
    id: 'category-form-details',
    name: 'Event',
    inputs: [
      {
        id: 'shirtSize',
        size: hasBibName || hasJersey ? 4 : 6,
        field: (
          <FormSelectInput
            name="shirtSize"
            label="T-shirt size"
            autoComplete="new-shirtSize"
            placeholder="Select T-shirt Size"
            disabled={formDisabled}
            options={Object.entries(TSHIRT_SIZES).map(([key, value]) => ({
              value: key,
              text: value,
            }))}
            required
          />
        ),
      },
      {
        ...(hasJersey && {
          id: 'jerseySize',
          size: 4,
          field: (
            <FormSelectInput
              name="jerseySize"
              label="Cycling jersey size"
              autoComplete="new-jerseySize"
              placeholder="Select Cycling Jersey Size"
              disabled={formDisabled}
              options={Object.entries(TSHIRT_SIZES).map(([key, value]) => ({
                value: key,
                text: value,
              }))}
              required
            />
          ),
        }),
      },
      {
        id: 'teamName',
        size: hasBibName || hasJersey ? 4 : 6,
        field: (
          <FormTextInput
            name="teamName"
            label="Team Name"
            autoComplete="new-teamName"
            disabled={formDisabled}
          />
        ),
      },
      {
        ...(hasBibName && {
          id: 'bibName',
          size: 4,
          field: (
            <FormTextInput
              name="bibName"
              label="Name in bib"
              autoComplete="new-bibName"
              disabled={formDisabled}
            />
          ),
        }),
      },
    ],
  },
  {
    id: 'user-form-details',
    name: 'Participant Information',
    inputs: [
      {
        id: 'firstName',
        size: { sm: 12, md: 6 },
        field: (
          <FormTextInput
            name="firstName"
            label="First Name"
            required
            autoComplete="new-firstName"
            disabled={showRepeater || formDisabled}
          />
        ),
      },
      {
        id: 'lastName',
        size: { sm: 12, md: 6 },
        field: (
          <FormTextInput
            name="lastName"
            label="Last Name"
            required
            autoComplete="new-lastName"
            disabled={showRepeater || formDisabled}
          />
        ),
      },
      {
        id: 'email',
        size: 6,
        field: (
          <FormTextInput
            name="email"
            label="Email Address"
            required
            autoComplete="new-email"
            disabled={formDisabled}
          />
        ),
      },
      {
        id: 'confirmEmail',
        size: 6,
        field: (
          <FormTextInput
            name="confirmEmail"
            label="Confirm Email Address"
            required
            autoComplete="new-confirmEmail"
            disabled={formDisabled}
            onCut={(e) => e.preventDefault()}
            onCopy={(e) => e.preventDefault()}
            onPaste={(e) => {
              e.preventDefault()
            }}
          />
        ),
      },
      {
        id: 'birthdate',
        size: { sm: 12, md: 4 },
        field: (
          <FormDatePicker
            name="birthdate"
            label="Birthdate"
            required
            autoComplete="new-birthdate"
            placeholder="YYYY-MM-DD"
            disabled={formDisabled}
          />
        ),
      },
      {
        id: 'nationality',
        size: { sm: 12, md: 4 },
        field: (
          <FormSelectInput
            name="nationality"
            label="Nationality"
            autoComplete="new-nationality"
            placeholder="Select Nationality"
            disabled={formDisabled}
            options={Object.entries(COUNTRIES).map(([key, value]) => ({
              value: key,
              text: value,
            }))}
            required
          />
        ),
      },
      {
        id: 'gender',
        size: { sm: 12, md: 4 },
        field: (
          <FormSelectInput
            name="gender"
            label="Gender"
            autoComplete="new-gender"
            placeholder="Select Gender"
            disabled={formDisabled}
            options={Object.entries(GENDERS).map(([key, value]) => ({
              value: key,
              text: value,
            }))}
            required
          />
        ),
      },
      {
        id: 'address',
        size: 12,
        field: (
          <FormTextInput
            name="address"
            label="Home Address"
            required
            autoComplete="new-address"
            disabled={formDisabled}
            placeholder={
              isVirtual ? 'Race kit will be delivered to this address' : ''
            }
          />
        ),
      },

      {
        id: 'phoneNumber',
        size: 6,
        field: (
          <FormTextInput
            name="phoneNumber"
            label="Phone Number"
            required
            autoComplete="new-phoneNumber"
            disabled={formDisabled}
          />
        ),
      },
      {
        id: 'confirmPhoneNumber',
        size: 6,
        field: (
          <FormTextInput
            name="confirmPhoneNumber"
            label="Confirm Phone Number"
            required
            autoComplete="new-confirmPhoneNumber"
            disabled={formDisabled}
            onCut={(e) => e.preventDefault()}
            onCopy={(e) => e.preventDefault()}
            onPaste={(e) => {
              e.preventDefault()
            }}
          />
        ),
      },
    ],
  },
  {
    id: 'user-emergency-form-details',
    name: 'Emergency Contact Person',
    inputs: [
      {
        id: 'emergencyContactName',
        size: 12,
        field: (
          <FormTextInput
            name="emergencyContactName"
            label="Emergency Contact Name"
            required
            autoComplete="new-emergencyContactName"
            disabled={formDisabled}
          />
        ),
      },
      {
        id: 'emergencyContactPhone',
        size: { sm: 12, md: 6 },
        field: (
          <FormTextInput
            name="emergencyContactPhone"
            label="Emergency Contact Number"
            required
            autoComplete="new-emergencyContactPhone"
            disabled={formDisabled}
          />
        ),
      },
      {
        id: 'confirmEmergencyContactPhone',
        size: { sm: 12, md: 6 },
        field: (
          <FormTextInput
            name="confirmEmergencyContactPhone"
            label="Confirm Emergency Contact Number"
            required
            autoComplete="new-confirmEmergencyContactPhone"
            disabled={formDisabled}
            onCut={(e) => e.preventDefault()}
            onCopy={(e) => e.preventDefault()}
            onPaste={(e) => {
              e.preventDefault()
            }}
          />
        ),
      },
    ],
  },
]
export default IndividualUserFormContent
