import CircularProgress from '@mui/material/CircularProgress'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'
import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

import useListParticipants from '../hooks/useListParticipants'

const sizes = `#4_BLUE|#4_WHITE|#4_ORANGE|#4_YELLOW GOLD|#4_PINK|#4_GREEN|#6_BLUE|#6_WHITE|#6_ORANGE|#6_YELLOW GOLD|#6_PINK|#6_GREEN|#8_BLUE|#8_WHITE|#8_ORANGE|#8_YELLOW GOLD|#8_PINK|#8_GREEN|#10_BLUE|#10_WHITE|#10_ORANGE|#10_YELLOW GOLD|#10_PINK|#10_GREEN|#12_BLUE|#12_WHITE|#12_ORANGE|#12_YELLOW GOLD|#12_PINK|#12_GREEN|#14_BLUE|#14_WHITE|#14_ORANGE|#14_YELLOW GOLD|#14_PINK|#14_GREEN|#16_BLUE|#16_WHITE|#16_ORANGE|#16_YELLOW GOLD|#16_PINK|#16_GREEN|#18_BLUE|#18_WHITE|#18_ORANGE|#18_YELLOW GOLD|#18_PINK|#18_GREEN|XS_BLUE|XS_WHITE|XS_ORANGE|XS_YELLOW GOLD|XS_PINK|XS_GREEN|S_BLUE|S_WHITE|S_ORANGE|S_YELLOW GOLD|S_PINK|S_GREEN|M_BLUE|M_WHITE|M_ORANGE|M_YELLOW GOLD|M_PINK|M_GREEN|L_BLUE|L_WHITE|L_ORANGE|L_YELLOW GOLD|L_PINK|L_GREEN|XL_BLUE|XL_WHITE|XL_ORANGE|XL_YELLOW GOLD|XL_PINK|XL_GREEN|2XL_BLUE|2XL_WHITE|2XL_ORANGE|2XL_YELLOW GOLD|2XL_PINK|2XL_GREEN|3XL_BLUE|3XL_WHITE|3XL_ORANGE|3XL_YELLOW GOLD|3XL_PINK|3XL_GREEN|4XL_BLUE|4XL_WHITE|4XL_ORANGE|4XL_YELLOW GOLD|4XL_PINK|4XL_GREEN`

const DownloadParticipants: React.FC = () => {
  const location = useLocation()

  const [categoryId, setCategoryId] = useState('')
  const [isFree, setIsFree] = useState(false)
  const [isFormParticipant, setIsFormParticipant] = useState(false)
  const [startTimestamp, setTimestamp] = useState(null)

  useEffect(() => {
    setCategoryId(new URLSearchParams(location.search).get('categoryId'))
    const isFree = new URLSearchParams(location.search).get('free')
    setIsFree(isFree !== null)
    setTimestamp(new URLSearchParams(location.search).get('startTimestamp'))

    const isFormParticipant = new URLSearchParams(location.search).get('fp')
    setIsFormParticipant(isFormParticipant !== null)
  }, [location])

  const { participants, formParticipantTshirtTotal, apiLoading } =
    useListParticipants({
      categoryId,
      isFree,
      startTimestamp,
      isFormParticipant,
    })

  return (
    <Container>
      {apiLoading && <CircularProgress />}

      {isFormParticipant && formParticipantTshirtTotal && (
        <div>
          {Object.keys(formParticipantTshirtTotal).map((tshirtTotal) => (
            <Typography
              sx={{ display: 'block' }}
              key={`tshirtTotal-${tshirtTotal}`}>
              {tshirtTotal}|{formParticipantTshirtTotal[tshirtTotal]}
            </Typography>
          ))}
          <br />
        </div>
      )}
      <Typography sx={{ display: 'block' }}>
        {!isFormParticipant
          ? `#|createdAt|firstName|lastName|birthdate|nationality|gender|shirtSize|jerseySize|additionalId|firstParticipantInfo|bibName|teamName|subCategory|relayEvent|phoneNumber|address|emergencyContactName|emergencyContactPhone|user email|user phoneNumber|virtualProgress|tandem participantName|tandem participant birthdate|tandem participant shirtSize|registeredBy`
          : `#|createdAt|id|firstName|lastName|status|fee|paymentMode|${sizes}|tshirtCount`}
      </Typography>
      {participants
        .sort((a, b) => {
          if (a.createdAt < b.createdAt) return -1
          if (a.createdAt > b.createdAt) return 1
          return 0
        })
        .map((p, i) => (
          <div key={`line${i}`}>
            {!isFormParticipant ? (
              <Typography key={p.id} sx={{ display: 'block' }}>
                {i + 1}|{p.createdAt || ''}|{p.firstName || ''}|
                {p.lastName || ''}|{p.birthdate || ''}|{p.nationality || ''}|
                {p.gender || ''}|{p.shirtSize || ''}|{p.jerseySize || ''}|
                {p.additionalId || ''}|{p.firstParticipantInfo || ''}|
                {p.bibName || ''}|{p.teamName.replace('|', '-') || ''}|
                {p.subCategory || ''}|{p.relayEvent || ''}|{p.phoneNumber || ''}
                |{p.address || ''}|{p.emergencyContactName || ''}|
                {p.emergencyContactPhone || ''}|{p.email || p.user?.email || ''}
                |{p.user?.phoneNumber || ''}|{p.virtualProgress || ''}|
                {p.tandemName || ''}|{p.tandemBirthdate || ''}|
                {p.tandemShirtSize || ''}|{p.registeredBy || ''}
              </Typography>
            ) : (
              <Typography key={p.id} sx={{ display: 'block' }}>
                {i + 1}|{p.createdAt || ''}|{p.participantId || ''}|
                {p.firstName || ''}|{p.lastName || ''}|{p.status || ''}|
                {p.fee || ''}|{p.paymentMode || ''}|
                {p.tshirtCountArr['#4_BLUE'] || 0}|
                {p.tshirtCountArr['#4_WHITE'] || 0}|
                {p.tshirtCountArr['#4_ORANGE'] || 0}|
                {p.tshirtCountArr['#4_YELLOW GOLD'] || 0}|
                {p.tshirtCountArr['#4_PINK'] || 0}|
                {p.tshirtCountArr['#4_GREEN'] || 0}|
                {p.tshirtCountArr['#6_BLUE'] || 0}|
                {p.tshirtCountArr['#6_WHITE'] || 0}|
                {p.tshirtCountArr['#6_ORANGE'] || 0}|
                {p.tshirtCountArr['#6_YELLOW GOLD'] || 0}|
                {p.tshirtCountArr['#6_PINK'] || 0}|
                {p.tshirtCountArr['#6_GREEN'] || 0}|
                {p.tshirtCountArr['#8_BLUE'] || 0}|
                {p.tshirtCountArr['#8_WHITE'] || 0}|
                {p.tshirtCountArr['#8_ORANGE'] || 0}|
                {p.tshirtCountArr['#8_YELLOW GOLD'] || 0}|
                {p.tshirtCountArr['#8_PINK'] || 0}|
                {p.tshirtCountArr['#8_GREEN'] || 0}|
                {p.tshirtCountArr['#10_BLUE'] || 0}|
                {p.tshirtCountArr['#10_WHITE'] || 0}|
                {p.tshirtCountArr['#10_ORANGE'] || 0}|
                {p.tshirtCountArr['#10_YELLOW GOLD'] || 0}|
                {p.tshirtCountArr['#10_PINK'] || 0}|
                {p.tshirtCountArr['#10_GREEN'] || 0}|
                {p.tshirtCountArr['#12_BLUE'] || 0}|
                {p.tshirtCountArr['#12_WHITE'] || 0}|
                {p.tshirtCountArr['#12_ORANGE'] || 0}|
                {p.tshirtCountArr['#12_YELLOW GOLD'] || 0}|
                {p.tshirtCountArr['#12_PINK'] || 0}|
                {p.tshirtCountArr['#12_GREEN'] || 0}|
                {p.tshirtCountArr['#14_BLUE'] || 0}|
                {p.tshirtCountArr['#14_WHITE'] || 0}|
                {p.tshirtCountArr['#14_ORANGE'] || 0}|
                {p.tshirtCountArr['#14_YELLOW GOLD'] || 0}|
                {p.tshirtCountArr['#14_PINK'] || 0}|
                {p.tshirtCountArr['#14_GREEN'] || 0}|
                {p.tshirtCountArr['#16_BLUE'] || 0}|
                {p.tshirtCountArr['#16_WHITE'] || 0}|
                {p.tshirtCountArr['#16_ORANGE'] || 0}|
                {p.tshirtCountArr['#16_YELLOW GOLD'] || 0}|
                {p.tshirtCountArr['#16_PINK'] || 0}|
                {p.tshirtCountArr['#16_GREEN'] || 0}|
                {p.tshirtCountArr['#18_BLUE'] || 0}|
                {p.tshirtCountArr['#18_WHITE'] || 0}|
                {p.tshirtCountArr['#18_ORANGE'] || 0}|
                {p.tshirtCountArr['#18_YELLOW GOLD'] || 0}|
                {p.tshirtCountArr['#18_PINK'] || 0}|
                {p.tshirtCountArr['#18_GREEN'] || 0}|
                {p.tshirtCountArr['XS_BLUE'] || 0}|
                {p.tshirtCountArr['XS_WHITE'] || 0}|
                {p.tshirtCountArr['XS_ORANGE'] || 0}|
                {p.tshirtCountArr['XS_YELLOW GOLD'] || 0}|
                {p.tshirtCountArr['XS_PINK'] || 0}|
                {p.tshirtCountArr['XS_GREEN'] || 0}|
                {p.tshirtCountArr['S_BLUE'] || 0}|
                {p.tshirtCountArr['S_WHITE'] || 0}|
                {p.tshirtCountArr['S_ORANGE'] || 0}|
                {p.tshirtCountArr['S_YELLOW GOLD'] || 0}|
                {p.tshirtCountArr['S_PINK'] || 0}|
                {p.tshirtCountArr['S_GREEN'] || 0}|
                {p.tshirtCountArr['M_BLUE'] || 0}|
                {p.tshirtCountArr['M_WHITE'] || 0}|
                {p.tshirtCountArr['M_ORANGE'] || 0}|
                {p.tshirtCountArr['M_YELLOW GOLD'] || 0}|
                {p.tshirtCountArr['M_PINK'] || 0}|
                {p.tshirtCountArr['M_GREEN'] || 0}|
                {p.tshirtCountArr['L_BLUE'] || 0}|
                {p.tshirtCountArr['L_WHITE'] || 0}|
                {p.tshirtCountArr['L_ORANGE'] || 0}|
                {p.tshirtCountArr['L_YELLOW GOLD'] || 0}|
                {p.tshirtCountArr['L_PINK'] || 0}|
                {p.tshirtCountArr['L_GREEN'] || 0}|
                {p.tshirtCountArr['XL_BLUE'] || 0}|
                {p.tshirtCountArr['XL_WHITE'] || 0}|
                {p.tshirtCountArr['XL_ORANGE'] || 0}|
                {p.tshirtCountArr['XL_YELLOW GOLD'] || 0}|
                {p.tshirtCountArr['XL_PINK'] || 0}|
                {p.tshirtCountArr['XL_GREEN'] || 0}|
                {p.tshirtCountArr['2XL_BLUE'] || 0}|
                {p.tshirtCountArr['2XL_WHITE'] || 0}|
                {p.tshirtCountArr['2XL_ORANGE'] || 0}|
                {p.tshirtCountArr['2XL_YELLOW GOLD'] || 0}|
                {p.tshirtCountArr['2XL_PINK'] || 0}|
                {p.tshirtCountArr['2XL_GREEN'] || 0}|
                {p.tshirtCountArr['3XL_BLUE'] || 0}|
                {p.tshirtCountArr['3XL_WHITE'] || 0}|
                {p.tshirtCountArr['3XL_ORANGE'] || 0}|
                {p.tshirtCountArr['3XL_YELLOW GOLD'] || 0}|
                {p.tshirtCountArr['3XL_PINK'] || 0}|
                {p.tshirtCountArr['3XL_GREEN'] || 0}|
                {p.tshirtCountArr['4XL_BLUE'] || 0}|
                {p.tshirtCountArr['4XL_WHITE'] || 0}|
                {p.tshirtCountArr['4XL_ORANGE'] || 0}|
                {p.tshirtCountArr['4XL_YELLOW GOLD'] || 0}|
                {p.tshirtCountArr['4XL_PINK'] || 0}|
                {p.tshirtCountArr['4XL_GREEN'] || 0}|{p.tshirtCount}
              </Typography>
            )}
          </div>
        ))}
    </Container>
  )
}
export default DownloadParticipants
