/* eslint-disable import/no-named-as-default-member */
/* eslint-disable @typescript-eslint/no-explicit-any */
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import * as Yup from 'yup'

import { TSHIRT_SIZES, GENDERS, COUNTRIES } from '../../../constants/models'

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.tz.setDefault('Asia/Manila')
dayjs.extend(customParseFormat)

const validateJerseyField = (value: any, testContext: any) => {
  if (testContext.parent.hasJersey)
    if (value !== undefined && value !== null) {
      return value && value.toString().trim().length > 0
    } else return false

  return true
}

const IndividualUserSchema = Yup.object().shape({
  firstName: Yup.string()
    .max(50, 'First name must not exceed 50 characters')
    .required('First Name is required'),
  lastName: Yup.string()
    .max(50, 'Last name must not exceed 50 characters')
    .required('Last Name is required'),
  email: Yup.string()
    .email('Email Address must be a valid email')
    .required('Email Address is required'),
  confirmEmail: Yup.string()
    .email('Confirm Email Address must be a valid email')
    .required('Confirm Email Address is required')
    .oneOf([Yup.ref('email'), null], 'Email addresses must match'),
  birthdate: Yup.mixed()
    .required('Birthdate is required')
    .test(
      'validate-format',
      'Birthdate must be valid date in format YYYY-MM-DD',
      (value) => {
        return dayjs(value as string, 'YYYY-MM-DD', true).isValid()
      },
    )
    .test('validate-future', 'Birthdate must not be a future date', (value) => {
      if (value !== undefined) return dayjs().isAfter(value as string)
    }),
  nationality: Yup.mixed()
    .required('Nationality is required')
    .oneOf(Object.keys(COUNTRIES)),
  gender: Yup.mixed()
    .required('Gender is required')
    .oneOf(Object.keys(GENDERS)),
  address: Yup.string()
    .max(100, 'Address must not exceed 100 characters')
    .required('Address is required'),
  phoneNumber: Yup.string().required('Phone number is required'),
  confirmPhoneNumber: Yup.string()
    .required('Confirm phone number is required')
    .oneOf([Yup.ref('phoneNumber'), null], 'Phone numbers must match'),
  bibName: Yup.string()
    //   .required('Name on bib is required')
    .max(8, 'Name on bib must not exceed 8 characters'),
  teamName: Yup.string().max(50, 'Team name must not exceed 50 characters'),
  shirtSize: Yup.mixed()
    .required('T-Shirt size is required')
    .oneOf(Object.keys(TSHIRT_SIZES)),
  categoryId: Yup.string().required('Category ID is required'),
  emergencyContactName: Yup.string()
    .max(50, 'Emergency Contact Name must not exceed 50 characters')
    .required('Emergency Contact Name is required'),
  emergencyContactPhone: Yup.string()
    .max(20, 'Emergency Contact Number must not exceed 20 characters')
    .required('Emergency Contact Number is required')
    .test(
      'not-equal-phoneNumber',
      'Emergency Contact Number must not be equal to Phone number',
      (value, testContext) => {
        if (
          'phoneNumber' in testContext.parent &&
          testContext.parent.phoneNumber.length > 0
        ) {
          return testContext.parent.phoneNumber !== value
        }
        return false
      },
    ),
  confirmEmergencyContactPhone: Yup.string()
    .required('Confirm Emergency Contact Number is required')
    .oneOf(
      [Yup.ref('emergencyContactPhone'), null],
      'Emergency Contact Numbers must match',
    ),

  // bike jersey
  hasJersey: Yup.boolean(),
  jerseySize: Yup.mixed()
    .test(
      'required-with-hasJersey',
      'Cycling jersey is required',
      validateJerseyField,
    )
    .oneOf(Object.keys(TSHIRT_SIZES)),
})

export default IndividualUserSchema
